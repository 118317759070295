import { useState } from "react";
import { useTranslation } from "react-i18next";
import c from "./style.module.css";
import Text from "../../../components/Text";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import LazyImage from "../../../components/LayzImage";
import { click } from "../../../assets";

export const Payments = () => {
  const { t } = useTranslation();
  const [sumToPay, setSumToPay] = useState("");
  return (
    <div className={`${c.payments__wrp} flex`}>
      <div className={c.payments__form}>
        <Text type="section-title">{t("pay")}</Text>
        <br />
        <br />
        {/* <form
          autoComplete="off"
          method="post"
          action={``}
        >
          <Input
            id="payment-btn"
            type="number"
            label={t("summa")}
            value={sumToPay}
            onChange={({ target }) => setSumToPay(target.value)}
          />
          <br />
          <Button text={t("click")} hovered />
        </form> */}
      </div>
      <div>
        <LazyImage
          src={click}
          alt="Pegas travel payments"
        />
      </div>
    </div>
  );
};
