import { Link } from "react-router-dom";
import { array, bool } from "prop-types";
import Text from "../Text";
import c from "./style.module.css";
import { useTranslation } from "react-i18next";

const ImageGallery = ({ images, isLink }) => {
  const {t} = useTranslation();
  return (
    <div
      className={images.length === 5 ? `${c.gallery__wrp__5}` : c.gallery__wrp}
    >
      {images?.map(({ id, img, title, details }, index) => (
        <div
          key={index}
          className={c.gallery__img}
          style={{ backgroundImage: `url(${img})` }}
        >
          <Link className={c.img__link} to={isLink ? "/tours/" + id : ""}>
            <div className={c.overlay}>
              <Text type="card">{t(title)}</Text>
              <Text type="details">{t(details)}</Text>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default ImageGallery;
ImageGallery.propTypes = {
  isLink: bool,
  images: array,
};
