import { useEffect, useState, lazy } from "react";
import { useLocation, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { Sidebar } from "./components/Sidebar";
const Tours = lazy(() => import("./pages/Home/Tours"));

const App = () => {
  const { pathname } = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isPageScrolled, setIsPageScrolled] = useState(false);

  const changeNavStyle = () => {
    window.scrollY > 50 ? setIsPageScrolled(true) : setIsPageScrolled(false);
  };
  useEffect(() => {
    window.addEventListener("scroll", changeNavStyle);

    return () => window.removeEventListener("scroll", changeNavStyle);
  }, []);
  useEffect(() => {
    setIsSidebarOpen(false);
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    document.body.style.overflow = isModalOpen ? "hidden" : "auto";
  }, [isModalOpen]);

  return (
    <div>
      <Navbar
        isPageScrolled={isPageScrolled}
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <Sidebar
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/tours/:id"
          element={
            <Tours isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
          }
        />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
