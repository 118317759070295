//?Images
import logo from "./Images/logo.svg";
import tours from "./Images/tours.svg";
import poxod from "./Images/poxod.webp";
import plane from "./Images/plane.webp";
import hotel from "./Images/hotel.webp";
import bus from "./Images/bus.webp";
import notes from "./Images/walk.png";
import click from "./Images/click.avif";
//?Icons
import planeIcon from "./Icons/plane.svg";
import uz from "./Icons/uz.svg";
import us from "./Icons/us.svg";
import ru from "./Icons/ru.svg";
import arrowRightIcon from "./Icons/arrow-right.svg";
import starIcon from "./Icons/star.svg";
import facebookIcon from "./Icons/facebook.svg";
import instagramIcon from "./Icons/instagram.svg";
import telegramIcon from "./Icons/telegram.svg";
import locationIcon from "./Icons/locate.svg";
import mailIcon from "./Icons/mail.svg";
import phoneIcon from "./Icons/phone.svg";

export const flags = [
  {
    id: 0,
    img: uz,
    value: "uz",
  },
  {
    id: 1,
    img: ru,
    value: "ru",
  },
  {
    id: 2,
    img: us,
    value: "us",
  },
];

export {
  logo,
  planeIcon,
  tours,
  arrowRightIcon,
  poxod,
  plane,
  hotel,
  bus,
  notes,
  starIcon,
  telegramIcon,
  facebookIcon,
  instagramIcon,
  locationIcon,
  mailIcon,
  phoneIcon,
  click
};
