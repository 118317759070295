import { useState } from "react";
import c from "./style.module.css";
import Text from "../../../components/Text";
import { galleryData } from "../../../static/gallery";
import ImageGallery from "../../../components/Gallery";
import { useTranslation } from "react-i18next";

export const Gallery = () => {
  const labels = [
    "all",
    "egypt",
    "azar",
    "madrid",
    "maldiv",
    "umra",
    "tailand",
    "vetnam",
    "srilan",
  ];
  const [selectedLabel, setselectedLabel] = useState(0);
  const { t } = useTranslation();
  return (
    <div>
      <Text type="section-title" center>
        {t("destpage_t")}
      </Text>
      <Text type="details" center>
        {t("destpage_d")}
      </Text>
      <div className={c.btn__labels}>
        {labels.map((text, index) => (
          <button
            key={text}
            className={
              selectedLabel === index
                ? `${c.label__btn} ${c.btn__active}`
                : `${c.label__btn}`
            }
            onClick={() => setselectedLabel(index)}
          >
            {t(text)}
          </button>
        ))}
      </div>
      <ImageGallery images={galleryData[selectedLabel]?.galleryImages} isLink />
    </div>
  );
};
