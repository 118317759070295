import LazyImage from "../LayzImage";
import c from "./style.module.css";
import {
  facebookIcon,
  instagramIcon,
  locationIcon,
  logo,
  mailIcon,
  phoneIcon,
  telegramIcon,
} from "../../assets";
import Text from "../Text";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const links = [
  {
    id: 0,
    key: "destinations",
    link: "destinations",
  },
  {
    id: 2,
    key: "services",
    link: "services",
  },
  {
    id: 3,
    key: "otziv",
    link: "otziv",
  },
];

const Footer = () => {
  const { t } = useTranslation();
  const handleScroll = (el) => {
    el.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <footer className={c.footer} id="contacts">
      <div className={`${c.footer__container} container flex`}>
        <ul className={c.footer__links}>
          <li>
            <LazyImage
              className={c.footer__logo}
              src={logo}
              alt="Pegas Travel"
            />
          </li>
          <li className="flex">
            <Link to="https://facebook.com/" target="_blank">
              <LazyImage
                className={c.sm__icon}
                src={facebookIcon}
                alt="Pegas Travel facebook"
              />
            </Link>
            <Link to="https://t.me/pegastravel" target="_blank">
              <LazyImage
                className={c.sm__icon}
                src={telegramIcon}
                alt="Pegas Travel telegram"
              />
            </Link>
            <Link
              to="https://www.instagram.com/pegastravel"
              target="_blank"
            >
              <LazyImage
                className={c.sm__icon}
                src={instagramIcon}
                alt="Pegas Travel instagram"
              />
            </Link>
          </li>
        </ul>
        <ul className={c.footer__links}>
          <li>
            <Text type="card">{t("bistry")}</Text>
          </li>
          <li>
            <Link to="/" onClick={() => window.scrollTo(0, 0)}>
              {t("home")}
            </Link>
          </li>
          {links.map(({ id, key, link }) => (
            <li key={id}>
              <Link
                to={key}
                onClick={(e) => {
                  e.preventDefault();
                  const targetElement = document.getElementById(key);
                  if (targetElement) {
                    handleScroll(targetElement);
                  }
                }}
              >
                {t(link)}
              </Link>
            </li>
          ))}
        </ul>
        <ul className={c.footer__links}>
          <li className="flex">
            <LazyImage className={c.footer__icon} src={locationIcon} alt="" />
            <Link>Toshkent region, Olmaliq city</Link>
          </li>
          <li className="flex">
            <LazyImage className={c.footer__icon} src={phoneIcon} alt="" />
            <Link to="tel:+998935607242">+998-93-560-72-42</Link>
          </li>
          <li className="flex">
            <LazyImage className={c.footer__icon} src={mailIcon} alt="" />
            <Link
              className={c.email}
              to=""
            >
              pegastravel@gmail.com
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
