import { bool, func } from "prop-types";
import { Link } from "react-router-dom";
import nav_routes from "../../routes";
import c from "./style.module.css";
import { useTranslation } from "react-i18next";

export const Sidebar = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const { t } = useTranslation();
  const handleScroll = (el) => {
    el.scrollIntoView({ behavior: "smooth", block: "start" });
    setIsSidebarOpen(false);
  };
  return (
    <div
      className={
        isSidebarOpen
          ? `${c.sidebar__wrp} ${c.sidebar__active}`
          : `${c.sidebar__wrp}`
      }
    >
      <ul className={c.sidebar__menu}>
        <li className={c.menu__item}>
          <Link
            className={c.menu__link}
            to="/"
            onClick={() => {
              window.scrollTo(0, 0);
              setIsSidebarOpen(false);
            }}
          >
            {t("home")}
          </Link>
        </li>
        {nav_routes.map(({ id, path }) => (
          <li key={id} className={c.menu__item}>
            <Link
              className={c.menu__link}
              to={path}
              onClick={(e) => {
                e.preventDefault();
                const targetElement = document.getElementById(path);
                if (targetElement) {
                  handleScroll(targetElement);
                }
              }}
            >
              {t(path)}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

Sidebar.propTypes = {
  isSidebarOpen: bool.isRequired,
  setIsSidebarOpen: func.isRequired,
};
