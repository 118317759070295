import c from "./style.module.css";
import Text from "../../../components/Text";
import { googleCardsData } from "../../../static/gallery";
import LazyImage from "../../../components/LayzImage";
import { starIcon } from "../../../assets";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const Reviews = () => {
  const { t } = useTranslation();
  const [showAllCardId, setShowAllCardId] = useState(null);
  return (
    <div className="container" id="otziv">
      <div className={c.section__head}>
        <Text type="label">{t("review_t")}</Text>
        <Text type="section-title">{t("review_d")}</Text>
      </div>

      <div className={c.cards}>
        {googleCardsData.map(({ id, title, details, name, img }) => (
          <div className={c.card} key={id}>
            <Text type="card">{t(title)}</Text>
            <div className={c.card__details}>
              <Text type="details">
                {showAllCardId === id
                  ? t(details)
                  : t(details).split(" ").slice(0, 12).join(" ")}
                <span
                  onClick={() => setShowAllCardId(id)}
                  className={c.view__more}
                  style={{ display: showAllCardId === id && "none" }}
                >
                 {t("show_all")}
                </span>
              </Text>
            </div>
            <div className={c.google__wrp}>
              <LazyImage
                className={c.star__icon}
                src={starIcon}
                alt="Pegas tour, Pegas travel"
              />
              <LazyImage
                className={c.star__icon}
                src={starIcon}
                alt="Pegas tour, Pegas travel"
              />
              <LazyImage
                className={c.star__icon}
                src={starIcon}
                alt="Pegas tour, Pegas travel"
              />
              <LazyImage
                className={c.star__icon}
                src={starIcon}
                alt="Pegas tour, Pegas travel"
              />
            </div>
            <Text type="details">{name}</Text>

            <LazyImage
              className={c.card__img}
              src={img}
              alt="Pegas tour, Pegas travel"
            />
          </div>
        ))}
      </div>
    </div>
  );
};
