import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
const Languages = ["uz", "en", "ru"];

i18n.use(initReactI18next)
    .use(Backend)
    .use(LanguageDetector).init({
        fallbackLng: "en",
        debug: false,
        whitelist: Languages,
        interpolation: {
            escapeValue: false,
        },
        detection: {
            order: ["cookie", "localStorage"],
            lookupCookie: "lang",
            lookupLocalStorage: "lang",
            caches: ["cookie", "localStorage"],
        },
        resources: {
            en: {
                translation: {
                    // navbar started
                    "hero_title": "ENJOY AND LIVE A NEW FULL LIFE",
                    "title_label": "TRAVEL,",
                    "hero_label": "Best Places Worldwide",
                    "about": "About",
                    "services": "Services",
                    "destinations": "Destinations",
                    "contacts": "Contacts",
                    // slider started
                    "category": "CATEGORIES",
                    "we_offer": "We Offer the Best Services",
                    "home": "Home",
                    "tour": "Tours",
                    "tour_info_0": "Immerse yourself in exciting adventures with our tours. From city tours to journeys – find your perfect vacation filled with impressions and comfort.",
                    "poxod": "Hiking",
                    "tour_info_1": "Discover the beauty of nature with us. Our hikes are suitable for all skill levels – from easy trails to thrilling mountain routes. The adventure starts here.",
                    "ticket": "Air Tickets",
                    "tour_info_2": "Ensure ease in every flight with our flight offers. A wide selection at affordable prices provides comfort and reliability in every air journey.",
                    "hotels": "Hotels",
                    "tour_info_3": "Find the perfect accommodation for your holiday with our diverse selection of hotels worldwide. Luxury resorts, cozy boutique hotels, or budget options – we have the perfect offer for everyone.",
                    "transfer": "Transfer",
                    "tour_info_4": "Ensure ease of movement with our convenient transfers. From the airport to your place of stay – our service guarantees comfort and reliability in every trip.",
                    "stra": "Insurance",
                    "tour_info_5": "Travel confidently with our insurance. Medical coverage, cancellation insurance, and baggage loss insurance will provide you peace of mind during adventures. Reliable support at every stage of your journey.",
                    // About started
                    "aboutpage_t": "Your Guide to the World of Travel",
                    "aboutpage_d": "Turning Your Travel Desires into Reality with Our Professionalism and Care",
                    "about_card0": "Travel",
                    "about_card1": "Awards",
                    "about_card2": "Years of Experience",
                    "learn_more": "Learn More",
                    
                    // REVIEWS started
                    "review_t": "REVIEWS",
                    "review_d": "What people are saying about us",
                    "otdix": "Classy Vacation: The Perfect Journey",
                    "review_0": "Thank you so much for an unforgettable trip! Professional team, exquisite places, and excellent service. It was the perfect vacation, leaving only positive memories in the heart!",
                    "lutshe": "The Best Vacation with Guaranteed Pleasure",
                    "review_1": "Thanks to this agency, my vacation became truly unforgettable! Excellent organization, attentive staff, and a sea of positive impressions. I recommend with confidence!",
                    "emotion": "Emotional Journey with Impressions",
                    "review_2": "Traveling with this agency exceeded all expectations! From caring service to amazing places, every moment is filled with delight. A true discovery of the joy of travel!",


                    // tours
                    "tourprice_t": "Affordable Price Tours",
                    "destpage_t": "Popular Destinations",
                    "destpage_d": "Selected routes for your unforgettable adventures",

                    "tour_turkey": "Tours to Turkey",
                    "turk_river": "Turkish Riviera",
                    "book_now": "Book now",
                    "turk": "Turkey",
                    "turk_vek": "Magnificent Century",
                    "turk_capa": "Cappadocia",

                    "tour_egypt": "Tours to Egypt",
                    "hotel_egypt": "Best Hotels in Egypt",
                    "waits_egypt": "What awaits you in Egypt",
                    "elsheyx": "Sharm el-Sheikh",
                    "info_t_78": "Duration: 8 days / 7 nights",
                    "info_price": "Price is per person based on double occupancy including airfare.",
                    "info_price_cb": "*Price may change according to the exchange rate",
                    "label": "Included in the price:",
                    "flight_direction_egypt": "Flight Tashkent - Sharm el-Sheikh - Tashkent",
                    "hotel": "Accommodation in the chosen hotel",
                    "baza": "All-inclusive meals",
                    "hotel_aero": "Group transfer airport-hotel-airport",

                    "egypt": "Egypt",
                    "pramid": "Egyptian Pyramids",
                    "sfx": "Great Sphinx",
                    "red_sea": "Red Sea",
                    "goramuso": "Mount Moses",
                    "diving": "Affordable Diving",
                    "motosafari": "Moto Safari",

                    "azar": "Azerbaijan",
                    "monastr_geo": "Gandzasar Monastery",
                    "incity": "Old City Icheri-Sheher",
                    "home_isa": "Isa-bek Gadzhinsky's House",
                    "door_geo": "Sheki Khan's Palace",
                    "mosque": "Mohammed Mosque",
                    "bulvar": "Seaside Boulevard",

                    "madrid": "Madrid",
                    "madrid_musey": "Lazaro Galdiano Museum",
                    "lasventas": "Las Ventas Arena",
                    "san_anton": "Hermitage Chapel San Antonio de la Florida",
                    "korolev_vek": "Royal Cathedral of St. Francis the Great",
                    "santana": "Santa Ana Square",
                    "bellas_art": "Círculo de Bellas Artes Viewing Platform",

                    "maldiv": "Maldives",
                    "diving_snor": "Diving and Snorkeling",
                    "sea": "Sea Excursions",
                    "delfin": "Swimming with Dolphins",
                    "ocean": "Ocean Sunset",
                    "relax": "Relaxation",
                    "surfing": "Surfing",

                    "umra": "Umrah",
                    "madina": "Medina",
                    "alkuba": "Al-Kuba Mosque",
                    "uhud": "Sacred Mount Uhud",
                    "sad": "Date Palm Garden",
                    "albaki": "Jannat al-Baqi",
                    "arafat": "Mount Arafat",
                    "quran": "King Fahd's Quran Printing Complex",

                    "tour_umra": "Holy Umra",
                    "info_t_134": "Included in the price:",
                    "flight_umra": "Flight Tashkent - Kuwait - Tashkent",
                    "return_flight": "Transfer to the airport in Jeddah (86 km)",
                    "return_flight_tashkent": "Flight Jeddah - Kuwait - Tashkent",
                    "hotel_period": "Stay in hotels in Medina (3 nights) and Mecca (10 nights), based on 4-person accommodation",
                    "meals": "Meals: Suhoor + Iftar (breakfasts + dinners)",
                    "transport": "Transportation according to the program",
                    "guide": "Guide throughout the tour",
                    "all_tours": "All specified excursions in Medina",
                    "excursions": "All specified excursions in Mecca",
                    "umra_book": "Umrah Book",
                    "badge": "Pilgrim Badge",
                    "zamzam": "Zamzam Water",
                    "bag": "Pilgrim Bag",
                    "coat": "Pilgrim Vest",
                    "gift": "Quran Book as a Gift",
                    
                    "tailand": "Tailand",
                    "pxuket": "Phuket",
                    "ostrova": "Island Excursions",
                    "zakati": "Best Sunsets",
                    "beach": "Beaches of Your Choice",
                    "bangla": "Bangla Road Nightlife",
                    
                    "vetnam": "Vietnam",
                    "fukok": "Fukuoka",
                    "beach_ostro": "Beaches and Islands",
                    "lux_service": "High-Level Service",
                    "kanat": "Cable Car",
                    "affordable_price": "Low Prices",
                    "memories": "Religious Monuments",
                    "vinland": "Vinpearl Land",

                    "tour_france": "Tours to France",
                    "hotel_france": "Best Hotels in France",
                    "flight_france": "Flight Tashkent - Istanbul - Paris - Istanbul - Tashkent (Turkish Airlines)",
                    "waits_france": "What awaits you in France",

                    "tour_europe": "Tours to Europe",
                    "italy": "Italy",
                    "spain": "Spain",
                    "france": "France",
                    
                    "tour_georgia": "Tours to Georgia",
                    "hotel_georgia": "Best Hotels in Georgia",
                    "flight_tbilisi": "Flight Tashkent - Tbilisi - Tashkent",
                    "waits_georgia": "What awaits you in Georgia",


                    "srilan": "Sri Lanka",
                    "landshaft": "Landscapes of Sri Lanka",
                    "dostrop": "Sights",
                    "selontea": "Ceylon Tea",
                    "live_world": "Animal World",
                    "Diving and Snorkeling": "Diving and Snorkeling",
                    "fruit_ray": "Fruit Paradise",
                    
                    "rome": "Rome",
                    "zamok": "Castle of St. Angelo",
                    "tour_italy": "Tours to Italy",
                    "hotel_aero_ind": "Individual transfer airport-hotel-airport",
                    "med": "Medical insurance",
                    "help_visa": "Assistance with visa application (document translation, embassy queue submission)",
                    "not_label": "Not included in the tour price:",
                    "flight_italy": "Flight Tashkent - Istanbul - Rome - Istanbul - Tashkent (Turkish Airlines)",
                    "visa_fee": "Visa fee for applying to the Italian embassy - 80 euros",
                    "ekskurs": "Excursions",

                    "waits_italy": "What awaits you in Italy",
                    "ploshad": "Navona Square",
                    "angela": "Castle of St. Angelo",
                    "vitto": "Vittoriano",
                    "trast": "Trastevere",
                    "madjor": "Santa Maria Maggiore",
                    "villa": "Villa Borghese",
                    

                    "kavkaz": "Caucasus",
                    "tour_kavkaz": "Tours to the Caucasus",
                    "paris": "Paris",
                    "korolev": "Royal Cathedral of St. Francis the Great",
                    "europe": "Europe",
                    "tour_eu": "Tours in Europe",
                    "georgia": "Georgia",
                    "monastr": "Jvari Monastery",
                    
                    // Payment
                    "pay": "Pay for the tour online",
                    "summa": "Amount",
                    "click": "Pay via CLICK",
                    //  footer
                    "bistry": "Quick Access",
                    "otziv": "Reviews",
                }
            },
            uz: {
                translation: {
                    "hero_title": "YANGI VA TO'LIQ HAYOTNI ROHATLARINGIZ BILAN O'TKAZING",
                    "title_label": "SAFAR,",
                    "hero_label": "Dunyodagi Eng Yaxshi Joylar",
                    "about": "Biz Haqimizda",
                    "services": "Xizmatlar",
                    "destinations": "Yo'nalishlar",
                    "contacts": "Aloqalar",
                    "category": "KATEGORIYALAR",
                    "we_offer": "Eng Yaxshi Xizmatlarni Taklif Qilamiz",
                    "home": "Bosh Sahifa",
                    "tour": "Sayohatlar",
                    "tour_info_0": "Sayohatlarimiz bilan hayajonli sarguzashtlarga sho'ng'ing. Shaharda sayohatdan tortib uzoq manzillarga qadar - taassurotlar va qulayliklarga to'la mukammal dam olishni toping.",
                    "poxod": "Piyoda Sayohat",
                    "tour_info_1": "Biz bilan tabiat go'zalligini kashf qiling. Bizning sayohatlarimiz barcha darajadagi sayohatchilar uchun mos keladi – oson yo’llardan tortib tog’li yo’llargacha. Sarguzashtlar shu yerdan boshlanadi.",
                    "ticket": "Aviabiletlar",
                    "tour_info_2": "Har bir parvozda qulaylikni ta'minlang. Keng tanlov, arzon narxlar va ishonchlilik bilan har bir parvozingiz oson o'tadi.",
                    "hotels": "Mehmonxonalar",
                    "tour_info_3": "Butun dunyo bo'ylab sayohatingiz uchun mukammal joyni toping. Hashamatli kurortlar, qulay butik mehmonxonalar yoki byudjet variantlari – har kimga mos keladigan takliflarimiz bor.",
                    "transfer": "Transfer",
                    "tour_info_4": "Bizning qulay transferlarimiz bilan harakatlanish qulayligini ta'minlang. Aeroportdan joylashadigan joyingizgacha – xizmatimiz har bir sayohatni qulay va ishonchli qiladi.",
                    "stra": "Sug'urta",
                    "tour_info_5": "Bizning sug'urtamiz bilan ishonch bilan sayohat qiling. Tibbiy sug'urta, bekor qilish sug'urtasi va bagaj yo'qotish sug'urtasi sizning sarguzashtlaringizda tinchlik beradi. Har bir bosqichda ishonchli yordam.",
                    "aboutpage_t": "Sayohatlar Dunyoning Yo'lboshchisi",
                    "aboutpage_d": "Bizning kasbiy mahorat va g'amxo'rligimiz bilan sayohat istaklaringizni haqiqatga aylantiramiz.",
                    "about_card0": "Sayohat",
                    "about_card1": "Mukofotlar",
                    "about_card2": "Yillik Tajriba",
                    "learn_more": "Batafsil Ma'lumot",
                    "review_t": "SHARHLAR",
                    "review_d": "Odamlar biz haqimizda nima deyishadi",
                    "otdix": "Mukammal Dam Olish: Mukammal Sayohat",
                    "review_0": "Unutilmas sayohat uchun katta rahmat! Professional jamoa, zo'r joylar va a'lo darajadagi xizmat. Bu mukammal dam olish bo'ldi va faqat ijobiy xotiralar qoldirdi!",
                    "lutshe": "Kafolatlangan Zavq bilan Eng Yaxshi Dam Olish",
                    "review_1": "Bu agentlik tufayli ta'tilim haqiqatan ham unutilmas bo'ldi! Ajoyib tashkilot, diqqatli xodimlar va juda ko'p ijobiy taassurotlar. Ishonch bilan tavsiya qilaman!",
                    "emotion": "Taassurotlarga Boy Emotsional Sayohat",
                    "review_2": "Ushbu agentlik bilan sayohat barcha kutganimdan oshdi! Xizmatdan tortib ajoyib joylargacha, har bir lahza zavqqa to'la. Sayohatning haqiqiy quvonchini kashf etish!",
                    "tourprice_t": "Arzon Narxlardagi Sayohatlar",
                    "destpage_t": "Mashhur Yo'nalishlar",
                    "destpage_d": "Unutilmas sarguzashtlaringiz uchun tanlangan yo'nalishlar",
                    "tour_turkey": "Turkiyaga Sayohatlar",
                    "turk_river": "Turk Riviera",
                    "book_now": "Hozir Band Qiling",
                    "turk": "Turkiya",
                    "turk_vek": "Ajoyib Asr",
                    "turk_capa": "Kapadokiya",
                    "tour_egypt": "Misrga Sayohatlar",
                    "hotel_egypt": "Misrdagi Eng Yaxshi Mehmonxonalar",
                    "waits_egypt": "Misrda Sizni Nimalar Kutmoqda",
                    "elsheyx": "Sharm al-Shayx",
                    "info_t_78": "Davomiyligi: 8 kun / 7 kecha",
                    "info_price": "Narx har bir kishi uchun ikki kishilik joyda yashash asosida, parvoz ham kiradi.",
                    "info_price_cb": "*Narx kursga qarab o'zgarishi mumkin",
                    "label": "Narxga kiritilgan:",
                    "flight_direction_egypt": "Toshkent - Sharm al-Shayx - Toshkent parvozi",
                    "hotel": "Tanlangan mehmonxonada yashash",
                    "baza": "Hammasi kiritilgan ovqatlanish",
                    "hotel_aero": "Aeroportdan mehmonxonagacha guruh transferi",
                    "egypt": "Misr",
                    "pramid": "Misr Piramidasi",
                    "sfx": "Buyuk Sfinx",
                    "red_sea": "Qizil Dengiz",
                    "goramuso": "Musoning Tog'i",
                    "diving": "Arzon Narxlarda Dayving",
                    "motosafari": "Moto Safari",
                    "azar": "Ozarbayjon",
                    "monastr_geo": "Gandzasar Monastiri",
                    "incity": "Icheri-Sheher Eski Shahar",
                    "home_isa": "Isa-bek Gadjinskiy Uyi",
                    "door_geo": "Sheki Xonlar Saroyi",
                    "mosque": "Muhammad Masjidi",
                    "bulvar": "Dengiz Bo'yi Bulvari",
                    "madrid": "Madrid",
                    "madrid_musey": "Lazaro Galdiano Muzeyi",
                    "lasventas": "Las Ventas Arenasi",
                    "san_anton": "San Antonio de la Florida Kapellasi",
                    "korolev_vek": "Muqaddas Fransisk Katedraly",
                    "santana": "Santa Ana Maydoni",
                    "bellas_art": "Círculo de Bellas Artes Kuzatuv Maydonchasi",
                    "maldiv": "Maldivlar",
                    "diving_snor": "Dayving va Snorkeling",
                    "sea": "Dengiz Sayohatlari",
                    "delfin": "Delfinlar bilan Suzish",
                    "ocean": "Okeandagi Quyosh Botishi",
                    "relax": "Dam Olish",
                    "surfing": "Sorfing",
                    "umra": "Umra",
                    "madina": "Madina",
                    "alkuba": "Al-Kuba Masjidi",
                    "uhud": "Uhud Tog'i",
                    "sad": "Xurmo Bog'i",
                    "albaki": "Jannat al-Baqi",
                    "arafat": "Arofat Tog'i",
                    "quran": "Qur'on Bosmaxonasi (Shoh Fohd)",
                    "tour_umra": "Muqaddas Umra",
                    "info_t_134": "Narxga kiritilgan:",
                    "flight_umra": "Toshkent - Quvayt - Toshkent parvozi",
                    "return_flight": "Jidda aeroportiga transfer (86 km)",
                    "return_flight_tashkent": "Jidda - Quvayt - Toshkent parvozi",
                    "hotel_period": "Madina (3 kecha) va Makkada (10 kecha) yashash, 4 kishilik xona asosida",
                    "meals": "Ovqatlanish: Sahur va Iftor (nonushta va kechki ovqat)",
                    "transport": "Dasturga muvofiq transport",
                    "guide": "Butun sayohat davomida yo'lboshchi",
                    "all_tours": "Madinadagi barcha ekskursiyalar",
                    "excursions": "Makkadagi barcha ekskursiyalar",
                    "umra_book": "Umra Kitobi",
                    "badge": "Yo'lboshchi uchun nishon",
                    "long_t": "Muzeyga sayohat",
                    "timeframe": "Ta'til davomiyligi",
                    "contact_us": "Biz bilan Bog'laning",
                    "contact_info": "Bog'lanish Ma'lumotlari",
                    "address": "Manzil",
                    "email": "Elektron Pochta",
                    "phone": "Telefon",
                    "question": "Savolingiz bormi?",
                    "send_message": "Xabar Yuboring",
                    "rights": "Barcha huquqlar himoyalangan",
                    "terms": "Foydalanish Shartlari",
                    "privacy": "Maxfiylik Siyosati",
                    "dev": "Dasturiy Ta'minot Ishlab Chiqish"
                }
            },
            ru: {
                translation: {
                    "hero_title": "ЖИВИТЕ НОВОЙ И ПОЛНОЙ ЖИЗНЬЮ С УДОВОЛЬСТВИЕМ",
                    "title_label": "ПУТЕШЕСТВУЙТЕ,",
                    "hero_label": "Лучшие Места в Мире",
                    "about": "О Нас",
                    "services": "Услуги",
                    "destinations": "Направления",
                    "contacts": "Контакты",
                    "category": "КАТЕГОРИИ",
                    "we_offer": "Мы Предлагаем Лучшие Услуги",
                    "home": "Главная",
                    "tour": "Туры",
                    "tour_info_0": "Окунитесь в захватывающие приключения с нашими турами. От городских экскурсий до дальних направлений — идеальный отдых, полный впечатлений и комфорта.",
                    "poxod": "Походы",
                    "tour_info_1": "Исследуйте природную красоту вместе с нами. Наши маршруты подходят для путешественников всех уровней — от легких прогулок до горных троп. Приключения начинаются здесь.",
                    "ticket": "Авиабилеты",
                    "tour_info_2": "Обеспечьте себе комфорт в каждом перелете. Широкий выбор, низкие цены и надежность гарантируют, что каждый ваш перелет будет удобным.",
                    "hotels": "Отели",
                    "tour_info_3": "Найдите идеальное место для проживания по всему миру. Роскошные курорты, уютные бутик-отели или бюджетные варианты — у нас есть предложения для каждого.",
                    "transfer": "Трансфер",
                    "tour_info_4": "Обеспечьте удобство передвижения с нашими трансферами. От аэропорта до места проживания — наша услуга сделает каждую поездку удобной и надежной.",
                    "stra": "Страховка",
                    "tour_info_5": "Путешествуйте уверенно с нашей страховкой. Медицинская страховка, страховка отмены поездки и страховка на случай потери багажа обеспечат вам спокойствие на каждом этапе вашего приключения.",
                    "aboutpage_t": "Ваш Путеводитель по Миру Путешествий",
                    "aboutpage_d": "Мы воплощаем ваши мечты о путешествиях в реальность благодаря нашему профессионализму и заботе.",
                    "about_card0": "Путешествие",
                    "about_card1": "Награды",
                    "about_card2": "Многолетний Опыт",
                    "learn_more": "Узнать Больше",
                    "review_t": "ОТЗЫВЫ",
                    "review_d": "Что о нас говорят",
                    "otdix": "Идеальный Отдых: Идеальное Путешествие",
                    "review_0": "Огромное спасибо за незабываемую поездку! Профессиональная команда, отличные места и великолепный сервис. Это был идеальный отдых, оставивший только положительные воспоминания!",
                    "lutshe": "Лучший Отдых с Гарантированным Удовольствием",
                    "review_1": "Благодаря этому агентству мой отпуск стал по-настоящему незабываемым! Отличная организация, внимательный персонал и масса положительных эмоций. Однозначно рекомендую!",
                    "emotion": "Эмоциональное Путешествие, Полное Впечатлений",
                    "review_2": "Путешествие с этим агентством превзошло все мои ожидания! От сервиса до удивительных мест — каждый момент был наполнен радостью. Откройте для себя истинное удовольствие от путешествий!",
                    "tourprice_t": "Туры по Доступным Ценам",
                    "destpage_t": "Популярные Направления",
                    "destpage_d": "Подобранные направления для незабываемых приключений",
                    "tour_turkey": "Туры в Турцию",
                    "turk_river": "Турецкая Ривьера",
                    "book_now": "Забронировать Сейчас",
                    "turk": "Турция",
                    "turk_vek": "Великолепный Век",
                    "turk_capa": "Каппадокия",
                    "tour_egypt": "Туры в Египет",
                    "hotel_egypt": "Лучшие Отели Египта",
                    "waits_egypt": "Что Вас Ждет в Египте",
                    "elsheyx": "Шарм-эль-Шейх",
                    "info_t_78": "Продолжительность: 8 дней / 7 ночей",
                    "info_price": "Цена указана за человека при проживании на базе двухместного размещения, включает перелет.",
                    "info_price_cb": "*Цена может измениться в зависимости от курса",
                    "label": "Включено в цену:",
                    "flight_direction_egypt": "Перелет Ташкент - Шарм-эль-Шейх - Ташкент",
                    "hotel": "Проживание в выбранном отеле",
                    "baza": "Питание: все включено",
                    "hotel_aero": "Групповой трансфер от аэропорта до отеля",
                    "egypt": "Египет",
                    "pramid": "Египетские Пирамиды",
                    "sfx": "Великий Сфинкс",
                    "red_sea": "Красное Море",
                    "goramuso": "Гора Моисея",
                    "diving": "Дайвинг по доступным ценам",
                    "motosafari": "Мото Сафари",
                    "azar": "Азербайджан",
                    "monastr_geo": "Монастырь Гандзасар",
                    "incity": "Старый Город Ичери-Шехер",
                    "home_isa": "Дом Исы-бека Гаджинского",
                    "door_geo": "Дворец Ханов в Шеки",
                    "mosque": "Мечеть Мухаммеда",
                    "bulvar": "Приморский Бульвар",
                    "madrid": "Мадрид",
                    "madrid_musey": "Музей Ласаро Гальдиано",
                    "lasventas": "Арена Лас Вентас",
                    "san_anton": "Часовня Святого Антония де ла Флорида",
                    "korolev_vek": "Кафедральный Собор Святого Франциска",
                    "santana": "Площадь Санта-Ана",
                    "bellas_art": "Смотровая Площадка Círculo de Bellas Artes",
                    "maldiv": "Мальдивы",
                    "diving_snor": "Дайвинг и Снорклинг",
                    "sea": "Морские Прогулки",
                    "delfin": "Плавание с Дельфинами",
                    "ocean": "Закаты над Океаном",
                    "relax": "Отдых",
                    "surfing": "Серфинг",
                    "umra": "Умра",
                    "madina": "Медина",
                    "alkuba": "Мечеть Аль-Куба",
                    "uhud": "Гора Ухуд",
                    "sad": "Финиковая Плантация",
                    "albaki": "Кладбище Аль-Баки",
                    "arafat": "Гора Арафат",
                    "quran": "Печатная Фабрика Корана (Король Фахд)",
                    "tour_umra": "Священная Умра",
                    "info_t_134": "Включено в цену:",
                    "flight_umra": "Перелет Ташкент - Кувейт - Ташкент",
                    "return_flight": "Трансфер в аэропорт Джидда (86 км)",
                    "return_flight_tashkent": "Перелет Джидда - Кувейт - Ташкент",
                    "hotel_period": "Проживание в Медине (3 ночи) и Мекке (10 ночей) на основе четырехместного размещения",
                    "meals": "Питание: Сухур и Ифтар (завтрак и ужин)",
                    "transport": "Транспорт по программе",
                    "guide": "Сопровождение гида на протяжении всего путешествия",
                    "all_tours": "Все экскурсии в Медине",
                    "excursions": "Все экскурсии в Мекке",
                    "umra_book": "Книга для Умры",
                    "badge": "Значок для гида",
                    "long_t": "Музейная Экскурсия",
                    "timeframe": "Продолжительность Путешествия",
                    "contact_us": "Свяжитесь с Нами",
                    "contact_info": "Контактная Информация",
                    "address": "Адрес",
                    "email": "Электронная Почта",
                    "phone": "Телефон",
                    "question": "Есть Вопрос?",
                    "send_message": "Отправить Сообщение",
                    "rights": "Все права защищены",
                    "terms": "Условия Использования",
                    "privacy": "Политика Конфиденциальности",
                    "dev": "Разработка Программного Обеспечения"
                }
            }
        },
    });

export default i18n;