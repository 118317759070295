import { string, bool, func } from "prop-types";
import "./style.css";
import LazyImage from "../LayzImage";

const Button = ({ text, icon, hovered, onClick, circle }) => {
  return (
    <button
      className={hovered ? "gray--btn hoverable" : "gray--btn oval"}
      onClick={onClick}
      style={circle && { borderRadius: "1rem" }}
    >
      {icon && (
        <LazyImage className="btn-icon" src={icon} alt={text + " button"} />
      )}
      {text}
    </button>
  );
};

export default Button;
Button.propTypes = {
  text: string,
  icon: string,
  hovered: bool,
  onClick: func,
  circle: bool,
};
